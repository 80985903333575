import React from "react";
import Fade from "react-reveal/Fade";
import ContactForm from "../../ContactForm/ContactForm";

// import cameras from "../../../img/headers/will-myers-NnODYexRg7o-unsplash-1-1-ConvertImage.jpg";

import "./practice.css";

export default function Practice() {
  const cameras =
    "https://firebasestorage.googleapis.com/v0/b/itay-investigation-new.appspot.com/o/headres%2Fwill-myers-NnODYexRg7o-unsplash-1-1-ConvertImage-min.jpg?alt=media&token=e329d0f3-54f2-4fed-a1af-2ed716610f92";

  const typeOverall = [
    "פרטים אישיים.",
    "חשבונות בנקים.",
    'נדל"ן.',
    "כלי רכב.",
    "גילוי מקורות הכנסה.",
    'הוצל"פ.',
    "תביעות.",
    "קופות גמל.",
    "קרנות השתלמות.",
    "ביטוחים.",
  ];

  const privateInvest = [
    "ליוויו בתהליכים משפטיים נגד גורמים.",
    "ליווי בהליכי גירושין.",
    "בדיקה דיסקרטית של בן/בת הזוג או של החתן/הכלה לעתיד.",
    'איסוף מידע כלכלי על בן/בת זוג (הפקדות לביטוחים, קרנות, משכנתאות, הלוואות, תוכניות חיסכון, מניות, סניפי בנק, נדל"ן וכלי רכב).',
    "הליכים פליליים ואזרחיים.",
  ];

  const technicalEquipment = [
    "מצלמות סמויות / גלויות.",
    "מערכות כניסה ויציאה.",
    "מערכות שליטה ובקרה /ואו מערכות משולבות טלוויזיה במעגל סגור (CCTV כולל ציוד אופטי מיוחד).",
    "ציוד נייד ומוסווה להקלטה ולצילום בהתאם לחוק הגנת הפרטיות וחוק האזנות סתר.",
    "מערכות הקלטה הנותנות מענה במקרים של עובדים סורחים, בדיקת שעוני נוכחות, צילום ותיעוד חדרי ישיבות, תיעוד פגישות ועוד.",
    "מערכות בקרת כניסה מסונכרנות למחשב או STAND ALONE , לצורך בקרה ופיקוח על כניסה ויציאה של עובדים.",
    "מערכות אזעקה מהמתוחכמות בשוק לצורך מיגון מבנים ומשרדים.",
  ];

  const findArr = [
    "איתור פרטים אישיים.",
    "איתור כתובת.",
    "איתורים לפי דף פייסבוק/ אינסטגרם",
    "איתורים לצורך מסירות משפטיות",
    "איתור מקום עבודה",
  ];

  return (
    <div className='section-container'>
      <Fade>
        <img className='about-img' src={cameras}></img>
      </Fade>
      <a href='#container'>
        <div className='arrow'></div>
      </a>
      <div id='container'></div>
      <Fade bottom>
        <h4 className='second-title'> חקירות כלליות </h4>
        <ul className='invest-list'>
          {typeOverall.map((item, i) => {
            return (
              <li key={i}>
                <h5 className='list-item'> {item} </h5>
              </li>
            );
          })}
        </ul>
      </Fade>
      <Fade bottom>
        <h4 className='second-title'> חקירות אישות </h4>
        <ul className='invest-list'>
          {privateInvest.map((item, i) => {
            return (
              <li key={i}>
                <h5 className='list-item'> {item} </h5>
              </li>
            );
          })}
        </ul>
      </Fade>
      <Fade bottom>
        <h4 className='second-title'> איתורים </h4>
        <ul className='invest-list'>
          {findArr.map((item, i) => {
            return (
              <li key={i}>
                <h5 className='list-item'> {item} </h5>
              </li>
            );
          })}
          {/* <li>
            <h5 className='list-text'>
              איתור אנשים הוא תחום שבו אנו מתמחים. איתור נעדרים הוא לרוב משימה
              לא קלה, שדורשת ברוב המקרים נחישות וקריאטיביות במציאת פתרון יעיל
              לבעיה.
              <b>קיימים הרבה מקרים בהם הנעדר עצמו לא מעוניין שימצאו אותו.</b>
            </h5>
            <br />
          </li>
          <li>
            <h5 className='list-text'>
              אנו מטפלים ביעילות מוכחת בנושאים אלו בארץ ובחו"ל, תוך הפעלת קשרים
              ומקורות מידע העומדים לרשותנו בכדי להגיע לנעדר. הניסיון רב השנים
              וקשרינו עם גופים ממסדיים, משטרתיים ואחרים, מעלים ברוב המקרים את
              אחוזי ההצלחה במציאת נעדרים.
            </h5>
            <br />
          </li>
          <li>
            <h5 className='list-text'>
              בנוסף לכך, אנחנו מאתרים כתובות, חשבונות בנקים, חייבים ומקומות
              תעסוקה.
            </h5>
            <br />
          </li> */}
        </ul>
      </Fade>
      <Fade bottom>
        <h4 className='second-title'> בדיקות פוליגרף</h4>
        <ul className='invest-list'>
          <li>
            <h5 className='list-text'>
              אנו מבצעים בדיקות פוליגרף מקצועיות באמצעות ציוד מתקדם וממוחשבות.
              בשל אמינותו, נבחר מכשיר הפוליגרף של AXCITION ליעיל ביותר על-ידי
              גופי המודיעין והמשטרה המובילים בעולם, כולל: NYPD ,FBI ,CIA ו-LAPD.
            </h5>
            <br />
          </li>
          <li>
            <h5 className='list-text'>
              בדיקת הפוליגרף מבוצעת ומפוענחת על-ידי טובי המומחים בתחום. בודקי
              הפוליגרף במשרדנו חברים באיגוד הפוליגרף הבינלאומי (APA), באיגוד
              בודקי הפוליגרף בישראל ובאיגוד הפוליגרף האירופאי.
            </h5>
            <br />
          </li>
        </ul>
      </Fade>
      <Fade bottom>
        <h4 className='second-title'> גילוי ובדיקת האזנות סתר</h4>
        <ul className='invest-list'>
          <li>
            <h5 className='list-text'>
              אנו מבצעים בדיקות האזנה תקופתיות עבור רשויות מקומיות, משרדים
              ממשלתיים, מפעלים מסחריים, מפעלי הייטק, משרדי עורכי דין ומוסדות
              רשמיים בישראל ובעולם. במידת הצורך, מתבצעות גם בדיקות פרטיות
              חד-פעמיות. הציוד האלקטרוני שעומד לרשותנו הוא משוכלל ומתקדם ביותר.
              במידה שיש חשד להאזנת סתר או צילום בביתכם, ברכבכם, במשרדכם או בקווי
              הטלפון שברשותכם, הציוד יאפשר לנו לאתר ביעילות ובמהירות את המיקום.
              <br />
              ניתן לבצע פעולות חקירה נוספות לאיסוף ראיות, לצורך הגשת תביעה
              משפטית בגין פגיעה בפרטיות.
            </h5>
            <br />
          </li>
        </ul>
      </Fade>
      {/* <Fade bottom>
        <h4 className='second-title'> ליווי ובניית מערך אבטחת מידע</h4>
        <ul className='invest-list'>
          <li>
            <h5 className='list-text'>
              מדי יום נחשפים עסקים ואנשים פרטיים רבים למצבים בהם נעשה שימוש לרעה
              במידע רגיש. המומחים שלנו מלווים אנשים ועסקים, המבקשים להקים מערכת
              אבטחת מידע בכל רמה נדרשת, לכל אורך תהליך: רכישת ציוד אבטחה, ייעוץ,
              הדרכה והקמת מערכי בקרה במשרדים.
            </h5>
            <br />
          </li>
        </ul>
      </Fade>
      <Fade bottom>
        <h4 className='second-title'> שירותי קב"ט</h4>
        <ul className='invest-list'>
          <li>
            <h5 className='list-text'>
              אנחנו מבצעים בדיקת אבטחה מקיפה הכוללת את השירותים הבאים: מיגון
              משרדים, בדיקות תנועה באזורים רגישים, בדיקה בנושא שמירת סודיות מצד
              העובדים, בדיקות מהימנות תקופתיות והקמת מערך אבטחה כולל לחברות
              ולעסקים. המשרד עומד בתקנים ובנהלים הביטחוניים והבטיחותיים, תוך
              שימת דגש על הגנת המידע הממוחשב והגנה על עובדי הארגון.
            </h5>
            <br />
          </li>
        </ul>
      </Fade>
      <Fade bottom>
        <h4 className='second-title'>
          בניית קו הגנה במקרים של הוצאת דיבה ופגיעה במוניטין
        </h4>
        <ul className='invest-list'>
          <li>
            <h5 className='list-text'>
              שמו הטוב של האדם ומוניטין החברה מהווים נכס עיקרי להתנהלות סדירה
              ומוצלחת של עסק או אדם פרטי. מקרים של לשון הרע, הוצאת דיבה ופגיעה
              במוניטין עלולים לפגוע בצורה משמעותית, עד כדי בלתי הפיכה, בתפקוד
              ובהתפתחות של עסק או של אדם מסוים, ועלולים להיות מכריעים בטווח
              הארוך, במידה שאינם מטופלים כראוי. במקרים אלו נדרשות תגובה מהירה
              ותוכנית יסודית, כדי להגן מפני פגיעה נוספת. המומחיות שלנו היא איסוף
              ראיות, ממצאים ופתרונות, כדי לספק עילה לתביעה משפטית או כל תוצאה
              רצויה אחרת.
            </h5>
            <br />
          </li>
        </ul>
      </Fade>
      <Fade bottom>
        <h4 className='second-title'>תחרות לא הוגנת וריגול תעשייתי</h4>
        <ul className='invest-list'>
          <li>
            <h5 className='list-text'>
              תחרות בין חברות ועסקים שונים לעיתים אינה עומדת בכללי משחק הוגנים.
              ריגול תעשייתי, זיופים, כניסה לא חוקית למערכות מחשוב של חברה
              מסוימת, גניבת מסמכים וזיופם – כל אלה הפכו לחלק מן השגרה היומיומית
              של הפעילות העסקית. אנחנו מספקים לכם הגנה, טיפול וסיוע במקרים של
              תחרות לא הוגנת. נשתמש בכל הכלים החוקיים העומדים לרשותנו, כדי למנוע
              "נזילה" של מידע רגיש נוסף או לאסוף ראיות ו/או נתונים רלבנטיים
              לתביעה משפטית.
            </h5>
            <br />
          </li>
        </ul>
      </Fade> */}
      <Fade bottom>
        <h4 className='second-title'>התקנת ציוד טכני גלוי או סמוי</h4>
        <ul className='invest-list'>
          <li>
            <h5 className='list-text'>
              אני יודעים למצוא ולהכין את הפתרון הנכון לכל בעיה ואנו נתקין את
              הציוד הדרוש לבנייני משרדים, למפעלים, לשדות תעופה ולרשויות מקומיות.
              ציוד מתקדם זה מספק מענה במקרים של גניבות, פריצות, התנכלויות
              ואיומים, וכן למניעת ריגול תעשייתי ועסקי.
            </h5>
            <br />
          </li>
          {/* {technicalEquipment.map((item, i) => {
            return (
              <li key={i}>
                <h5 className='list-item'> {item} </h5>
              </li>
            );
          })} */}
        </ul>
      </Fade>
      <Fade>
        <h5 className='bold-text'>
          <b>
            אנחנו עוקבים, חוקרים, מתשאלים, מאזינים, מאתרים בהתאם לדרישותיכם,
            ולבסוף מדווחים על הממצאים. דוחותינו כתובים בצורה ברורה וקלה להבנה,
            כדי שתוכלו להפיק מהם את המיטב ולקבל את ההחלטות הנכונות.
          </b>
        </h5>
      </Fade>
      <Fade>
        <div className='practice-img'>
          <img src='https://firebasestorage.googleapis.com/v0/b/itay-investigation-new.appspot.com/o/homeGrid%2Fcameras.webp?alt=media&token=f8d853a7-9bc6-418c-9cac-119fcd43d754' />
        </div>
      </Fade>
      <Fade bottom>
        <div className='form-title-container'>
          <h3 className='contact-title'>מה תרצו לדעת?</h3>
          <ContactForm />
        </div>
      </Fade>
    </div>
  );
}
